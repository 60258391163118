import { useEffect, useState } from "react";
// import axios from "axios";
// import ListingItem from "../ListingItem";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import InfiniteScroll from "react-infinite-scroller";
import { useSelector } from "react-redux";
import LoadingComp from "../../components/common/LoadingComp";
import axiosGeneral from "../../utils/axiosGeneral";

import { Col, Radio, Row } from "antd";
import { useLocation } from "react-router-dom";
import GroupListingItem from "./GroupListingItem";
export default function GroupListing(props) {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterCurrentPage, setFilterCurrentPage] = useState(0);
  const [sorting, setSorting] = useState("alphabetical");
  const [value, setValue] = useState("asc");
  const search = useLocation().search;
  const keyword = new URLSearchParams(search).get("keyword");
  const name = new URLSearchParams(search).get("name");
  const user = useSelector((state) => state.user.user);
  const [isSearchedData, setIsSearchedData] = useState(false);
  const [groupsLength, setGroupsLength] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [isGroupAction, setIsGroupAction] = useState(false);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.create({
      trigger: "#load-more",
      onEnter: (self) => {
        console.log("Load More", self.isActive);
      },
      onLeave: (self) => {
        console.log("Stop loading more", self.isActive);
      },
    });
  }, []);

  useEffect(() => {
    let searchFlag = false;
    setIsLoader(true);
    setLoading(true);
    let APIURL = "";
    if (keyword) {
      searchFlag = true;
      APIURL = `/api/group/list?keyword=${
        keyword || ""
      }&searchDataPage=${0}&sort=${sorting}&OrderBy=${value}`;
    } else {
      setIsSearchedData(false);
      APIURL = `/api/group/list?page=${0}&sort=${sorting}&OrderBy=${value}`;
    }

    axiosGeneral()
      .post(APIURL, { userId: user.id })
      .then((res) => {
        if (res && res.status == 200 && res.data.groups) {
          setGroups(res.data.groups);
          setGroupsLength(res.data.length);
          if (res.data.groups.length == 0 || res.data.groups.length < 12) {
            setLoading(false);
          }
          if (searchFlag) {
            setFilterCurrentPage(1);
          } else {
            setCurrentPage(1);
          }
        }
        setIsLoader(false);
      })
      .catch((err) => {
        console.log(err.response);
        setIsLoader(false);
      });
  }, [search]);

  const loadMoreBusinesses = (page) => {
    let API_URL = "";

    if (keyword) {
      API_URL = `/api/group/list?keyword=${
        keyword || ""
      }&searchDataPage=${filterCurrentPage}&sort=${sorting}&OrderBy=${value}`;
    } else {
      API_URL = `/api/group/list?page=${currentPage}&sort=${sorting}&OrderBy=${value}`;
    }

    axiosGeneral()
      .post(API_URL, { userId: user.id })
      .then((res) => {
        setGroups(groups.concat(res.data.groups));
        setGroupsLength(res.data.length);
        if (res.data.groups.length == 0 || res.data.groups.length < 12) {
          setLoading(false);
        }

        if (keyword) {
          setFilterCurrentPage(filterCurrentPage + 1);
        } else {
          setCurrentPage(currentPage + 1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSorting = (val) => {
    setLoading(true);
    setSorting(val);

    let API_URL = "";

    if (keyword) {
      API_URL = `/api/group/list?keyword=${keyword || ""}&name=${
        name || ""
      }&searchDataPage=${0}&sort=${val}&OrderBy=${value}`;
    } else {
      API_URL = `/api/group/list?page=${0}&sort=${val}&OrderBy=${value} `;
    }

    axiosGeneral()
      .post(API_URL, { userId: user.id })
      .then((res) => {
        setGroups(res.data.groups);
        setGroupsLength(res.data.length);
        if (res.data.groups.length == 0 || res.data.groups.length < 12) {
          setLoading(false);
        }

        if (keyword) {
          setFilterCurrentPage(1);
        } else {
          setCurrentPage(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChange = (e) => {
    setLoading(true);
    setValue(e.target.value);

    let API_URL = "";

    if (keyword) {
      API_URL = `/api/group/list?keyword=${keyword || ""}&name=${
        name || ""
      }&searchDataPage=${0}&sort=${sorting}&OrderBy=${e.target.value} `;
    } else {
      API_URL = `/api/group/list?page=${0}&sort=${sorting}&OrderBy=${
        e.target.value
      }`;
    }

    axiosGeneral()
      .post(API_URL, { userId: user.id })
      .then((res) => {
        setGroups(res.data.groups);
        setGroupsLength(res.data.length);
        if (res.data.groups.length == 0 || res.data.groups.length < 12) {
          setLoading(false);
        }

        if (keyword) {
          setFilterCurrentPage(1);
        } else {
          setCurrentPage(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (isLoader) {
    return <div className="loader" id="loader"></div>;
  } else {
    return (
      <div id="group-listing" className="listing-page">
        <div className="listing-header">
          <h4 className="results-num">
            {" "}
            Found{" "}
            {groups.length +
              ((currentPage + 1) * 12 - groups.length) -
              ((currentPage + 1) * 12 - groups.length)}{" "}
            of {groupsLength} Results
          </h4>

          <div className="sort-form">
            <form>
              <label>
                <span>Sort By</span>

                <select onChange={(e) => handleSorting(e.target.value)}>
                  <option value="alphabetical">Alphabetical</option>
                  <option value="latest">Latest</option>
                </select>
              </label>
            </form>

            <Radio.Group onChange={onChange} value={value}>
              <Radio value="asc" className="radioVal">
                ASC
              </Radio>
              <Radio value="desc" className="radioVal">
                DESC
              </Radio>
            </Radio.Group>
          </div>
        </div>

        <InfiniteScroll
          pageStart={0}
          loadMore={loadMoreBusinesses}
          hasMore={loading}
          loader={<LoadingComp />}
        >
          <div className="bizzll-groups">
            {groups.map((group) => {
              return (
                <GroupListingItem
                  key={group.id}
                  group={group}
                  isGroupAction={isGroupAction}
                  setIsGroupAction={setIsGroupAction}
                />
              );
            })}
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}
