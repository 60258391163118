//** Import Modules */
import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Col, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Advertisements from "../ads/Advertisements";
const { Option } = Select;

export default function PageTitle(props) {
  const { searchData, cities, businesses } = props;
  const search = useLocation().search;
  const [keyword, setKeyword] = useState(
    new URLSearchParams(search).get("keyword")
  );
  const [location, setLocation] = useState(
    new URLSearchParams(search).get("location")
  );
  const [category, setCategory] = useState(
    new URLSearchParams(search).get("category")
  );
  const [status, setStatus] = useState(
    new URLSearchParams(search).get("status")
  );
  const [ratings, setRatings] = useState(
    new URLSearchParams(search).get("ratings")
  );

  let navigate = useNavigate();

  let locationQuery = "";
  let keywordQuery = "";
  let CategoryQuery = "";
  let ratingsQuery = "";
  let statusQuery = "";

  if (location) {
    locationQuery = `&location=${location || ""}`;
  }
  if (keyword) {
    keywordQuery = `&keyword=${keyword || ""}`;
  }
  if (category) {
    CategoryQuery = `&category=${category || ""}`;
  }
  if (ratings) {
    ratingsQuery = `&ratings=${ratings || ""}`;
  }
  if (status) {
    statusQuery = `&status=${status || ""}`;
  }

  useEffect(() => {
    if (!search) {
      setKeyword(null);
      setCategory(null);
      setLocation(null);
      setRatings(null);
      setStatus(null);
    } else {
      setKeyword(new URLSearchParams(search).get("keyword"));
      setCategory(new URLSearchParams(search).get("category"));
      setLocation(new URLSearchParams(search).get("location"));
      setRatings(new URLSearchParams(search).get("ratings"));
      setStatus(new URLSearchParams(search).get("status"));
    }
  }, [search]);

  // SEARCH ON Enter press
  const handleSearch = (event) => {
    if (event.key === "Enter" || event == "isSearch") {
      if (keyword || location || category || ratings || status) {
        navigate(
          `/directory?searchDataPage=${0}${locationQuery}${keywordQuery}${CategoryQuery}${ratingsQuery}${statusQuery}`
        );
      } else {
        navigate(`/directory`);
      }
    }
  };

  // SEARCH ON Dropdown value select
  const handleSelectedValue = (value, option) => {
    if (value) {
      navigate(
        `/directory?searchDataPage=${0}&location=${
          value || ""
        }${keywordQuery}${CategoryQuery}${ratingsQuery}${statusQuery}`
      );
    } else {
      navigate(
        `/directory?searchDataPage=${0}${keywordQuery}${CategoryQuery}${ratingsQuery}${statusQuery}`
      );
    }
  };
  const handleCitiesRender = (allCities) => {
    return allCities.map((city) => {
      return {
        ...city,
        value: city.name,
        key: "name",
      };
    });
  };

  return props.cityPage ? (
    <div
      id="page-title"
      style={{
        marginBottom: "0px",
      }}
    >
      <div className="inner-container">
        <h1>{props.pageTitle}</h1>
        {!props.cityPage && (
          <Advertisements
            size="banner"
            type="Advertisement"
            addLocation="pageTitle"
          />
        )}
      </div>
    </div>
  ) : (
    <div id="page-title">
      <div
        className={
          props.displaySearch ? "inner-container has-search" : "inner-container"
        }
      >
        <h1 className="title-text">{props.pageTitle}</h1>

        {props.displaySearch && (
          <div className="directory_search">
            <Input.Group>
              <Input
                type="text"
                name="keyword"
                placeholder="Food, clothes, restaurants"
                onChange={(e) => setKeyword(e.target.value)}
                value={keyword ? keyword : ""}
                onKeyDown={handleSearch}
              />

              <AutoComplete
                style={{
                  width: "100%",
                }}
                placeholder={"City"}
                filterOption={location ? true : false}
                notFoundContent={null}
                onChange={(value) => setLocation(value)}
                value={location}
                onSelect={handleSelectedValue}
                onKeyDown={handleSearch}
                // children={<Input type="text" onKeyDown={handleSearch} />}
              >
                {cities && cities.length
                  ? cities.map((city) => {
                      return <Option key={city.name}>{city.name}</Option>;
                    })
                  : null}
              </AutoComplete>

              <Button
                icon={<SearchOutlined />}
                className="directory-search-icon"
                onClick={() => handleSearch("isSearch")}
              />
            </Input.Group>
          </div>
        )}
      </div>
    </div>
  );
}
