import { Button, Form, Input, Modal, Rate } from "antd";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
// import UploadMedia from "./UploadMedia";
import UploadMedia from "../../../common/UploadMedia";

const CreateAdModal = (props) => {
  const [form] = Form.useForm();
  const recaptchaRef = useRef(null);
  const {
    showModal,
    setShowModal,
    handleSubmit,
    isSubmitting,
    advertisement,
    commentModal,
    preFilledData,
    replyCommentModal,
    reviewSection,
  } = props;
  const [isValidCaptcha, setIsValidCaptcha] = useState("");
  const handleOnChange = (value) => {
    if (value) {
      setIsValidCaptcha("");
    }
  };
  return (
    <div>
      <Modal
        title={
          advertisement
            ? reviewSection
              ? "Edit Review"
              : "Update Post"
            : commentModal
            ? "Add Review"
            : "Create an Post"
        }
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
          form.resetFields();
        }}
        footer={[]}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            text: advertisement
              ? advertisement.text
              : advertisement && advertisement.text && !replyCommentModal
              ? advertisement.text
              : "",
            attachments: advertisement ? advertisement.attachments : "",
            rating:
              advertisement && advertisement.rating ? advertisement.rating : 1,
            user_id: advertisement && advertisement.id ? advertisement.id : "",
          }}
          onFinish={async (values) => {
            const formData = new FormData();
            formData.append("file", values.attachments);
            let captchaToken;
            captchaToken = await recaptchaRef?.current?.getValue();
            recaptchaRef?.current?.reset();
            // }
            const response = await handleSubmit({
              formData,
              values,
              captchaToken,
            });

            if (!advertisement) {
              if (preFilledData) {
              } else {
                console.log("Entro a comprobar si completo el submit");
                form.resetFields();
                setShowModal(false);
              }
            }
          }}
          onFinishFailed={() => console.log("failed")}
          autoComplete="off"
        >
          <Form.Item
            label="Text"
            name="text"
            rules={[
              {
                required: commentModal ? false : true,
                message: commentModal
                  ? "Please enter text for your Review"
                  : "Please enter text for your advertisement",
              },
            ]}
          >
            <Input.TextArea
              disabled={isSubmitting}
              defaultValue={
                preFilledData && preFilledData.text && !replyCommentModal
                  ? preFilledData.text
                  : ""
              }
            />
          </Form.Item>
          {commentModal || reviewSection ? (
            <Form.Item
              name="rating"
              label="Rate"
              rules={[
                {
                  required: commentModal && true,
                  message: "Please Select Your Rating",
                },
              ]}
            >
              <Rate
                allowClear={false}
                defaultValue={
                  advertisement && advertisement.rating
                    ? advertisement.rating
                    : 1
                }
              />
            </Form.Item>
          ) : reviewSection ? null : (
            <Form.Item
              name="attachments"
              rules={[{ required: true, message: "Please input an image!" }]}
            >
              <UploadMedia
                setMedia={(file) => form.setFieldsValue({ attachments: file })}
                maxCount={1}
                label="Post Image"
                aspectRatio={1.91}
                fieldName="attachments"
                form={form}
              />
            </Form.Item>
          )}
          <Form.Item
            label="editComment"
            name="user_id"
            style={{
              display: "none",
            }}
          >
            <Input
              defaultValue={
                preFilledData && preFilledData.id ? preFilledData.id : ""
              }
            />
          </Form.Item>
          {commentModal || reviewSection ? (
            <>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY}
                size="normal"
                className="g-recaptcha"
                onChange={handleOnChange}
              />
              {isValidCaptcha && (
                <h4
                  style={{
                    color: "#F00000",
                  }}
                >
                  {isValidCaptcha}
                </h4>
              )}
            </>
          ) : null}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              className="add-post-btn btn-left"
            >
              {isSubmitting
                ? "Submitting"
                : advertisement
                ? "Update"
                : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CreateAdModal;
