//** Import Modules */
import { Helmet } from "react-helmet";

//** Import Assets */
import "../../assets/css/Directory.css";
import { Divider } from "antd";

//** Import Components */
import PageTitle from "../../components/header/PageTitle";
import CityListing from "./CityListing";
import CitySidebar from "./CitySidebar";
import Advertisements from "../../components/ads/Advertisements";

export default function City() {
  return (
    <div id="bizzll-page">
      <Helmet>
        <title>Bizzll Cities</title>
        <meta name="description" content="This is the Cities of Bizzll" />
      </Helmet>

      <PageTitle pageTitle="Cities" />

      <div id="bizzll-cities" className="page-with-sidebar">
        <div className="inner-container">
          <aside className="page-sidebar">
            <CitySidebar mainCityPage={true} />
          </aside>
          <div className="page-content">
            <Advertisements type="carousel" />
            <Divider />
            <CityListing />
          </div>
        </div>
      </div>
    </div>
  );
}
