import React, { useEffect, useState } from "react";
import axiosWithAuth from "../../utils/axiosWithAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Form,
  Input,
  InputNumber,
  Divider,
  Modal,
  Button,
  message,
  Drawer,
  Radio,
  Tabs,
  Alert,
  Steps,
  Checkbox,
} from "antd";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { FE_URL } from "../../common/constants";

export default function ProfileRestaurantsPage(props) {
  const { business } = props;

  const { Step } = Steps;

  const [menuItems, setMenuItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [SelectedMenuItem, setSelectedMenuItem] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [cart, setCart] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [total, setTotal] = useState(0);
  const [activeKey, setActiveKey] = useState("1");
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedExtras, setSelectedExtras] = useState([]);

  //Radio Button Group
  const [enable, setEnable] = useState(1);

  useEffect(() => {
    const APIEndPoint = `/api/restaurant/dish/${business.id}/categ`;
    axiosWithAuth()
      .get(APIEndPoint)
      .then((res) => {
        setMenuItems(res.data);
        console.log("res ssssssssssss sssssssssssssss", res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [business.id]);

  /* Calendar */

  const selectedMenuItem = (id) => {
    document
      .querySelectorAll(".single-menuItem-container_select")
      .forEach((element) => element.classList.remove("selected_service"));
    document.getElementById(id).classList.add("selected_service");
    menuItems.map((menuItem) => {
      if (menuItem.dish_id == id) {
        setSelectedMenuItem(menuItem);
        setModalOpen(true);
        console.log("selectedMenuItem", SelectedMenuItem);
        console.log("modalOpen", modalOpen);
      }
    });
  };

  const handleExtrasChange = (checkedValues) => {
    setSelectedExtras(checkedValues);
  };

  const hideModal = (ref) => {
    setModalOpen(false);
  };

  const onFinish = async (values) => {
    // next();
    console.log("valuesssssssssssssssssssssssssss", values);
    setUserInfo(values);
  };

  // const addToCart = async () => {
  //   const newCart = [...cart];
  //   let added = false;

  //   for (let i = 0; i < newCart.length && added == false; i++) {
  //     if (newCart[i].itemID === SelectedMenuItem.id) {
  //       newCart[i].itemQuantity = newCart[i].itemQuantity + quantity;
  //       added = true;
  //     }
  //   }

  //   if (added === false) {
  //     var cartItem = {
  //       itemID: SelectedMenuItem.dish_id,
  //       itemName: SelectedMenuItem.dish_name,
  //       itemPrice:
  //         SelectedMenuItem.on_offer === 1
  //           ? SelectedMenuItem.dish_offer_price
  //           : SelectedMenuItem.dish_price,
  //       itemQuantity: quantity,
  //       businessID: SelectedMenuItem.restaurant_id,
  //       itemTotal:
  //         total +
  //         (SelectedMenuItem.on_offer === 1
  //           ? SelectedMenuItem.dish_offer_price
  //           : SelectedMenuItem.dish_price) *
  //           quantity,
  //       businessStripeID: business.meta_data.stripe_account_id,
  //       itemType: "restaurant_product",
  //       itemDescription: SelectedMenuItem.dish_description,
  //       startTime: moment().format("YYYY-MM-DD hh:mm:ss"),
  //     };

  //     newCart.push(cartItem);
  //   }

  //   setCart(newCart);
  //   setOpen(false);
  //   setTotal(
  //     total +
  //       (SelectedMenuItem.on_offer === 1
  //         ? SelectedMenuItem.dish_offer_price
  //         : SelectedMenuItem.dish_price) *
  //         quantity
  //   );

  //   message.success("Item Added to Cart!");
  // };

  const addToCart = async () => {
    const newCart = [...cart];
    let added = false;

    // Obtener el precio base del plato
    const basePrice =
      SelectedMenuItem.on_offer === 1
        ? SelectedMenuItem.dish_offer_price
        : SelectedMenuItem.dish_price;

    // Formatear los extras seleccionados
    const formattedExtras = selectedExtras.map((extra) => ({
      id: extra.id,
      quantity: 1,
    }));

    // Calcular el precio total de los extras seleccionados
    const extrasTotal = selectedExtras.reduce(
      (sum, extra) => sum + extra.price,
      0
    );

    for (let i = 0; i < newCart.length && !added; i++) {
      if (newCart[i].itemID === SelectedMenuItem.dish_id) {
        newCart[i].itemQuantity += quantity;
        newCart[i].itemTotal += (basePrice + extrasTotal) * quantity;
        added = true;
      }
    }

    if (!added) {
      var cartItem = {
        itemID: SelectedMenuItem.dish_id,
        itemName: SelectedMenuItem.dish_name,
        itemPrice: basePrice,
        itemQuantity: quantity,
        businessID: SelectedMenuItem.restaurant_id,
        extras: formattedExtras, // Agregamos los extras seleccionados
        itemTotal: total + (basePrice + extrasTotal) * quantity, // Se suma el precio de los extras
        businessStripeID: business.meta_data.stripe_account_id,
        itemType: "restaurant_product",
        itemDescription: SelectedMenuItem.dish_description,
        startTime: moment().format("YYYY-MM-DD hh:mm:ss"),
      };

      newCart.push(cartItem);
    }

    setCart(newCart);
    setOpen(false);
    setTotal(total + (basePrice + extrasTotal) * quantity); // Actualizar el total correctamente

    message.success("Item Added to Cart!");
  };

  const handleCheckout = async () => {
    if (enable === 1 && userInfo === null) {
      setActiveKey("2");
      onClose();
      message.error(
        "Please complete the 'Your Info' section before proceeding to checkout"
      );
    } else {
      // Check to ensure that there are legitimate tickets being called
      if (cart && cart.length > 0) {
        // First, remove any item from localstorage
        localStorage.removeItem("cart");
        localStorage.removeItem("userInfo");

        // Then, add the new item in the local storage
        window.localStorage.setItem("cart", JSON.stringify(cart));
        window.localStorage.setItem("userInfo", JSON.stringify(userInfo));

        console.log("cart", cart);

        // redirect to checkout
        window.location.replace(`${FE_URL}/checkout`);
      }
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onChange = (e) => {
    setEnable(e.target.value);
  };

  const YourInfo = () => {
    return (
      <div>
        <Form layout="vertical" onFinish={onFinish}>
          {console.log("menuitems", menuItems)}
          <div className="service_container-info">
            <Radio.Group onChange={onChange} value={enable}>
              <Radio value={1}>Delivery</Radio>
              <Radio value={0}>Pickup</Radio>
            </Radio.Group>
            <Divider />
            {enable === 1 && (
              <div>
                <h3>Your Info</h3>
                <Form.Item
                  name="first name"
                  label="First name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your first name",
                    },
                  ]}
                >
                  <Input placeholder="Enter your first name" />
                </Form.Item>
                <Form.Item
                  name="last name"
                  label="Last name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your last name",
                    },
                  ]}
                >
                  <Input placeholder="Enter your email address" />
                </Form.Item>
                <Form.Item
                  name="phone_number"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your phone number",
                    },
                  ]}
                >
                  <Input placeholder="Enter your phone number" />
                </Form.Item>
                <Form.Item
                  name="address"
                  label="Address"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your address",
                    },
                  ]}
                >
                  <Input placeholder="Enter your address" />
                </Form.Item>
                <Form.Item
                  name="zip_code"
                  label="Zip Code"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your zip code",
                    },
                  ]}
                >
                  <Input placeholder="Enter your zip code" />
                </Form.Item>
              </div>
            )}
            <div
              style={{
                marginTop: 24,
                float: "right",
                display: "flex",
              }}
            >
              <Form.Item
                style={{
                  marginBottom: 0,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    );
  };

  const Menu = () => {
    return (
      <div>
        <div className="menuItem_container-menuItems">
          {Object.entries(
            menuItems.reduce((groups, item) => {
              if (item.is_available === 1) {
                if (!groups[item.category]) {
                  groups[item.category] = [];
                }
                groups[item.category].push(item);
              }
              return groups;
            }, {})
          ).map(([category, items], index, arr) => (
            <div key={category} className="menu-category">
              {index > 0 && <Divider />}
              <h3 className="menu-category-title">{category}</h3>
              {items.map((menuItem, itemIndex) => (
                <div
                  key={menuItem.dish_id + "_" + itemIndex}
                  className="single-menuItem-container"
                >
                  <div
                    className="single-menuItem-container_select"
                    id={menuItem.dish_id}
                    onClick={(element) => {
                      selectedMenuItem(element.target.id);
                    }}
                  ></div>
                  <div className="single-menuItem-container_image">
                    {console.log("menuItem", menuItem)}
                    <img
                      src={menuItem.dish_image || "/placeholder.jpg"}
                      alt={menuItem.dish_name}
                    />
                  </div>
                  <div className="single-menuItem-container_data">
                    <div className="single-menuItem-container_data-row-1">
                      <label>{menuItem.dish_name}</label>
                    </div>
                    <div className="single-menuItem-container_data-row-2">
                      <div className="single-menuItem-container_data-row-2-price">
                        <label>Price: </label>
                        <label className="single-menuItem-container_data-row-2-price-label">
                          ${" "}
                          {menuItem.on_offer === 1
                            ? menuItem.dish_offer_price
                            : menuItem.dish_price}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  };

  //Change quantity from cart
  const changeHandler = (index, value) => {
    const currentCart = [...cart];
    // setTotal(total - (currentCart[index].itemQuantity * currentCart[index].itemPrice));
    currentCart[index].itemQuantity = value;
    let total_temp = 0;
    currentCart.forEach((element) => {
      console.log("element", element);
      total_temp += element.itemQuantity * element.itemPrice;
    });
    setTotal(total_temp);
    setCart(currentCart);
  };

  //Delete from cart
  const removeHandler = (index) => {
    const currentCart = [...cart];
    setTotal(
      total - currentCart[index].itemQuantity * currentCart[index].itemPrice
    );
    currentCart.splice(index, 1);
    setCart(currentCart);
  };

  return (
    <>
      <Alert
        message="Attention Required"
        description={
          <>
            Please complete the 'Your Info' section before proceeding to
            checkout.
          </>
        }
        type="warning"
        showIcon
      />
      <Divider />
      <FontAwesomeIcon icon={faCartShopping} onClick={showDrawer} />
      <span className="cart_quantity">{cart.length}</span>
      <Drawer
        title={
          <div style={{ width: "100%", display: "flex" }}>
            <p style={{ fontWeight: "bold" }}>CART</p>{" "}
            <p style={{ margin: "0px 10px", fontWeight: "bold" }}>-</p>{" "}
            <p style={{ color: "gray", fontWeight: "bold" }}>
              {cart.length} Item
            </p>
          </div>
        }
        placement="right"
        onClose={onClose}
        visible={open}
        width={500}
      >
        {cart.length > 0 &&
          cart.map((product, index) => {
            return (
              <>
                <div>
                  <div
                    key={product + "_" + index}
                    className="single-menuItem-container-cart"
                  >
                    <div className="single-menuItem-container-cart_image">
                      <img src={SelectedMenuItem.dish_image} alt="Logo" />
                    </div>
                    <div className="single-menuItem-container-cart_data">
                      <div className="single-menuItem-container_data-row-1">
                        <label>{product.itemName}</label>
                      </div>
                      <div className="single-menuItem-container_data-row-2-duration">
                        <label>Quantity: </label>{" "}
                        <label className="single-menuItem-container_data-row-2-duration-label">
                          {product.itemQuantity}
                        </label>
                      </div>
                      <div className="single-menuItem-container_data-row-2-price">
                        <label>Price: </label>
                        <label className="single-menuItem-container_data-row-2-price-label">
                          $ {product.itemPrice}
                        </label>
                      </div>
                    </div>
                    <div className="single-menuItem-container-cart_quantity">
                      <InputNumber
                        min={1}
                        max={999}
                        defaultValue={product.itemQuantity}
                        onChange={(value) => changeHandler(index, value)}
                      />
                    </div>
                    <div
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you wish to delete this item from your cart?"
                          )
                        ) {
                          removeHandler(index);
                        }
                      }}
                      className="single-menuItem-container-cart_delete"
                    >
                      <DeleteOutlined style={{ cursor: "pointer" }} />
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        {cart.length > 0 && (
          <>
            <Divider />
            <div style={{ width: "100%", height: "10%", display: "flex" }}>
              <div style={{ width: "50%", alignSelf: "center" }}>
                <p style={{ float: "left", margin: "0" }}>Total:</p>
              </div>
              <div style={{ width: "50%", alignSelf: "center" }}>
                <p
                  style={{
                    float: "right",
                    fontWeight: "bold",
                    margin: "0",
                  }}
                >
                  $ {total}
                </p>
              </div>
            </div>
            <div style={{ display: "flex", margin: "auto" }}>
              <Button
                onClick={() => {
                  handleCheckout();
                }}
                type="primary"
              >
                Checkout
              </Button>
            </div>
          </>
        )}
        {cart.length == 0 && (
          <p
            style={{
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            You have no items in your cart
          </p>
        )}
        <Button
          onClick={() => {
            window.localStorage.setItem("cart", JSON.stringify(cart));
          }}
        ></Button>
      </Drawer>

      <Modal
        centered
        visible={modalOpen}
        onCancel={hideModal}
        footer={null}
        width={1000}
        className="restaurant_modal"
      >
        {SelectedMenuItem != null && (
          <div className="modal_container">
            <div className="modal_image">
              <img
                src={SelectedMenuItem.dish_image}
                alt={SelectedMenuItem.dish_name}
              />
            </div>
            <div className="modal_data">
              {/* <Steps current={currentStep}>
                <Step title="Details" />
                <Step title="Confirmation" />
              </Steps> */}
              <Divider />
              {currentStep === 0 ? (
                <div>
                  <h3>{SelectedMenuItem.dish_name}</h3>
                  <Divider />
                  <h4>{SelectedMenuItem.dish_description}</h4>
                  <Divider />
                  <h5>
                    {SelectedMenuItem.on_offer === 1
                      ? SelectedMenuItem.dish_offer_price
                      : SelectedMenuItem.dish_price}
                    $
                  </h5>
                  <Divider />
                  <InputNumber
                    addonBefore="Quantity"
                    min={1}
                    max={10}
                    defaultValue={1}
                    onChange={(value) => setQuantity(value)}
                  />
                  <Divider />
                  <Button type="primary" onClick={() => setCurrentStep(1)}>
                    Next
                  </Button>
                </div>
              ) : (
                <div>
                  <h3>Select Addons</h3>
                  <Divider />
                  <Checkbox.Group
                    options={SelectedMenuItem.extras.map((extra) => ({
                      label: `${extra.name} (+$${extra.price})`,
                      value: extra,
                    }))}
                    onChange={handleExtrasChange}
                  />
                  <Divider />
                  <Button type="primary" onClick={() => setCurrentStep(0)}>
                    Back
                  </Button>
                  <Button
                    type="primary"
                    onClick={addToCart}
                    style={{ marginLeft: 8 }}
                  >
                    Order
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </Modal>

      <div className="menuItem_container">
        <Tabs
          activeKey={activeKey}
          onChange={(key) => setActiveKey(key)}
          type="card"
          size="default"
        >
          <Tabs.TabPane
            tab={
              <span>
                <FontAwesomeIcon
                  icon="fa-solid fa-list"
                  style={{ marginRight: 8 }}
                />
                Menu
              </span>
            }
            key="1"
          >
            <Menu />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <span>
                <FontAwesomeIcon
                  icon="fa-solid fa-id-card"
                  style={{ marginRight: 8 }}
                />
                Your Info
              </span>
            }
            key="2"
          >
            <YourInfo />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  );
}
