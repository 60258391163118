import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import LoadingComp from "../../components/common/LoadingComp";
import { useSelector } from "react-redux";
import axiosGeneral from "../../utils/axiosGeneral";
import axiosWithAuth from "../../utils/axiosWithAuth";
import ListingItem from "./ListingItem";

import { Col, Radio, Row, Pagination, Divider } from "antd";
import { useLocation } from "react-router-dom";

export default function DirectoryListing(props) {
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterCurrentPage, setFilterCurrentPage] = useState(0);
  const [sorting, setSorting] = useState("alphabetical");
  const [value, setValue] = useState("asc");
  const search = useLocation().search;
  const keyword = new URLSearchParams(search).get("keyword");
  const location = new URLSearchParams(search).get("location");
  const category = new URLSearchParams(search).get("category");
  const ratings = new URLSearchParams(search).get("ratings");
  const tags = new URLSearchParams(search).get("tags");
  const status = new URLSearchParams(search).get("status");
  const user = useSelector((state) => state.user.user);
  const [userRoleAccess, setUserRoleAccess] = useState([]);

  // State to determine when a user is logged in
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSearchedData, setIsSearchedData] = useState(false);
  const [businessesLength, setBusinessesLength] = useState(0);
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.create({
      trigger: "#load-more",
      onEnter: (self) => {
        console.log("Load More", self.isActive);
      },
      onLeave: (self) => {
        console.log("Stop loading more", self.isActive);
      },
    });
  }, []);

  useEffect(() => {
    let searchFlag = false;
    setIsLoader(true);
    setLoading(true);
    let APIURL;

    let locationQuery = "";
    let nameQuery = "";
    let CategoryQuery = "";
    let ratingsQuery = "";
    let filteredTagQuery = "";
    let statusQuery = "";

    if (location) {
      locationQuery = `&location=${location || ""}`;
    }
    if (keyword) {
      nameQuery = `&keyword=${keyword || ""}`;
    }
    if (category) {
      CategoryQuery = `&category=${category || ""}`;
    }
    if (ratings) {
      ratingsQuery = `&ratings=${ratings || ""}`;
    }

    if (tags && tags.length > 0) {
      filteredTagQuery = `&tags=${tags || ""}`;
    }
    if (status) {
      statusQuery = `&status=${status || ""}`;
    }

    if (
      keyword ||
      location ||
      category ||
      ratings ||
      (tags && tags.length) ||
      status
    ) {
      searchFlag = true;
      APIURL = `/api/business/list?searchDataPage=${
        currentPage - 1
      }${locationQuery}${nameQuery}&sort=${sorting}&OrderBy=${value}${filteredTagQuery}${statusQuery}`;
      if (category || ratings) {
        APIURL = `/api/business/list?searchDataPage=${
          currentPage - 1
        }${locationQuery}${nameQuery}${CategoryQuery}${ratingsQuery}&sort=${sorting}&OrderBy=${value}${filteredTagQuery}${statusQuery}`;
      }
    } else {
      // setCurrentPage(1);
      setIsSearchedData(false);
      APIURL = `/api/business/list/?page=${currentPage - 1}&tags=${
        tags || ""
      }&status=${status || ""}&sort=${sorting}&OrderBy=${value}`;
    }

    axiosGeneral()
      .get(APIURL)
      .then((res) => {
        if (res && res.status === 200 && res.data.businesses) {
          // setIsSearchedData(true);
          setBusinesses(res.data.businesses);
          setBusinessesLength(res.data.length);
          // props.onTagList(res.data.tags);
          getAllTags();
          if (
            res.data.businesses.length === 0 ||
            res.data.businesses.length < 12
          ) {
            setLoading(false);
            // message.info(`Fetched All ${businessesLength} Businesses`)
          }
          if (!searchFlag) {
            // setFilterCurrentPage(1);
            setCurrentPage(1);
          }
          // else {
          //   setCurrentPage(1);
          // }
        }
        setIsLoader(false);
      })
      .catch((err) => {
        console.log(err.response);
        setIsLoader(false);
      });
  }, [search, currentPage]);

  useEffect(() => {
    // Logic for checking if have an auth token
    const getToken = JSON.parse(localStorage.getItem("token"));

    if (getToken) {
      if ("token" in getToken) {
        setIsLoggedIn(true);
      }
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn || !userRoleAccess.length) {
      getUserRoleAccess();
    }
  }, [user]);

  const getUserRoleAccess = async () => {
    if (user && user.id) {
      const response = await axiosWithAuth().get(
        `${process.env.REACT_APP_BE_API_URL}/api/users/roleAccessBusiness`
      );
      if (response && response.status == 200) {
        setUserRoleAccess(response.data);
      }
    }
  };

  const getAllTags = async () => {
    const response = await axiosWithAuth().get(
      `${process.env.REACT_APP_BE_API_URL}/api/business/getTags/type/business`
    );
    if (response && response.status === 200) {
      props.onTagList(response.data);
    }
  };

  const updateCurrentPageState = async () => {
    setCurrentPage(currentPage + 1);
  };

  const loadMoreBusinesses = (page) => {
    let API_URL = "";

    let locationQuery = "";
    let nameQuery = "";
    let CategoryQuery = "";
    let ratingsQuery = "";
    let filteredTagQuery = "";
    let statusQuery = "";

    if (location) {
      locationQuery = `&location=${location || ""}`;
    }
    if (keyword) {
      nameQuery = `&keyword=${keyword || ""}`;
    }
    if (category) {
      CategoryQuery = `&category=${category || ""}`;
    }
    if (ratings) {
      ratingsQuery = `&ratings=${ratings || ""}`;
    }
    if (tags && tags.length > 0) {
      filteredTagQuery = `&tags=${tags || ""}`;
    }
    if (status) {
      statusQuery = `&status=${status || ""}`;
    }

    if (
      search &&
      (keyword ||
        category ||
        location ||
        category ||
        ratings ||
        status ||
        (tags && tags.length))
    ) {
      API_URL = `/api/business/list?searchDataPage=${
        currentPage - 1
      }${CategoryQuery}${locationQuery}${nameQuery}${statusQuery}${ratingsQuery}${filteredTagQuery}&sort=${sorting}&OrderBy=${value}`;
    } else {
      API_URL = `/api/business/list?page=${
        currentPage - 1
      }&sort=${sorting}&OrderBy=${value}`;
    }

    axiosGeneral()
      .get(API_URL)
      .then((res) => {
        setBusinesses(businesses.concat(res.data.businesses));
        setBusinessesLength(res.data.length);
        if (
          res.data.businesses.length === 0 ||
          res.data.businesses.length < 12
        ) {
          setLoading(false);
        }
        // if (search) {
        //   setFilterCurrentPage(filterCurrentPage + 1);
        // } else {
        setCurrentPage(currentPage + 1);
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSorting = async (val) => {
    setLoading(true);
    setIsLoader(true);
    setSorting(val);
    setValue(value || "asc");
    let API_URL = "";

    let locationQuery = "";
    let nameQuery = "";
    let CategoryQuery = "";
    let ratingsQuery = "";
    let filteredTagQuery = "";
    let statusQuery = "";

    if (location) {
      locationQuery = `&location=${location || ""}`;
    }
    if (keyword) {
      nameQuery = `&keyword=${keyword || ""}`;
    }
    if (category) {
      CategoryQuery = `&category=${category || ""}`;
    }
    if (ratings) {
      ratingsQuery = `&ratings=${ratings || ""}`;
    }
    if (tags && tags.length > 0) {
      filteredTagQuery = `&tags=${tags || ""}`;
    }
    if (status) {
      statusQuery = `&status=${status || ""}`;
    }

    if (
      search &&
      (keyword ||
        location ||
        category ||
        ratings ||
        status ||
        (tags && tags.length))
    ) {
      API_URL = `/api/business/list?searchDataPage=${currentPage}${locationQuery}${filteredTagQuery}${nameQuery}${statusQuery}${CategoryQuery}${ratingsQuery}&sort=${val}&OrderBy=${
        value || "asc"
      }`;
    } else {
      API_URL = `/api/business/list/?page=${0}&sort=${val}&OrderBy=${
        value || "asc"
      } `;
    }

    const response = await axiosGeneral().get(API_URL);

    if (response && response.status == 200) {
      setBusinesses(response.data.businesses);
      setBusinessesLength(response.data.length);
      if (
        response.data.businesses.length == 0 ||
        response.data.businesses.length < 12
      ) {
        setLoading(false);
      }
      // if (search) {
      //   setFilterCurrentPage(1);
      // } else {
      setCurrentPage(1);
      // }
      setIsLoader(false);
    }
  };

  const onChange = (e) => {
    setLoading(true);
    setIsLoader(true);
    setValue(e.target.value);
    setSorting(sorting || "alphabetical");
    if (search) {
      let SEARCH_DATA_API;

      let locationQuery = "";
      let nameQuery = "";
      let CategoryQuery = "";
      let ratingsQuery = "";
      let filteredTagQuery = "";
      let statusQuery = "";

      if (location) {
        locationQuery = `&location=${location || ""}`;
      }
      if (keyword) {
        nameQuery = `&keyword=${keyword || ""}`;
      }
      if (category) {
        CategoryQuery = `&category=${category || ""}`;
      }
      if (ratings) {
        ratingsQuery = `&ratings=${ratings || ""}`;
      }
      if (tags && tags.length > 0) {
        filteredTagQuery = `&tags=${tags || ""}`;
      }
      if (status) {
        statusQuery = `&status=${status || ""}`;
      }

      if (
        keyword ||
        location ||
        category ||
        ratings ||
        status ||
        (tags && tags.length)
      ) {
        SEARCH_DATA_API = `/api/business/list?keyword=${
          keyword || ""
        }&location=${location || ""}&searchDataPage=${currentPage - 1}&sort=${
          sorting || "alphabetical"
        }&OrderBy=${e.target.value} `;
        if (category || ratings) {
          SEARCH_DATA_API = `/api/business/list?searchDataPage=${
            currentPage - 1
          }${locationQuery}${statusQuery}${nameQuery}${filteredTagQuery}${CategoryQuery}${ratingsQuery}&sort=${
            sorting || "alphabetical"
          }&OrderBy=${e.target.value}`;
        }
      }
      axiosGeneral()
        .get(SEARCH_DATA_API)
        .then((res) => {
          setBusinesses(res.data.businesses);
          setBusinessesLength(res.data.length);
          if (
            res.data.businesses.length == 0 ||
            res.data.businesses.length < 12
          ) {
            setLoading(false);
          }
          setIsLoader(false);
          // setFilterCurrentPage(1);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const APIURL = `/api/business/list?page=${0}&sort=${
        sorting || "alphabetical"
      }&OrderBy=${e.target.value}`;

      axiosGeneral()
        .get(APIURL)
        .then((res) => {
          setBusinesses(res.data.businesses);
          setBusinessesLength(res.data.length);
          if (
            res.data.businesses.length == 0 ||
            res.data.businesses.length < 12
          ) {
            setLoading(false);
          }
          setIsLoader(false);
          setCurrentPage(1);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  if (isLoader) {
    return <div className="loader" id="loader"></div>;
  } else {
    return (
      <div id="directory-listing" className="listing-page">
        <Divider />
        <div className="listing-header">
          <h4 className="results-num">
            Found{" "}
            {businesses.length +
              ((currentPage + 1) * 12 - businesses.length) -
              ((currentPage + 1) * 12 - businesses.length)}{" "}
            of {businessesLength} Results
          </h4>

          <div className="sort-form">
            <form>
              <label>
                <span>Sort By</span>

                <select
                  onChange={(e) => handleSorting(e.target.value)}
                  defaultValue={sorting}
                >
                  <option value="alphabetical">Alphabetical</option>
                  <option value="latest">Latest</option>
                  <option value="highest_rated">Highest Rated</option>
                </select>
              </label>
            </form>

            <Radio.Group onChange={onChange} value={value}>
              <Radio value="asc" className="radioVal">
                ASC
              </Radio>
              <Radio value="desc" className="radioVal">
                DESC
              </Radio>
            </Radio.Group>
          </div>
        </div>

        <Pagination
          align="end"
          current={currentPage}
          total={businessesLength}
          pageSize={12}
          showSizeChanger={false}
          onChange={(value) => {
            setCurrentPage(value);
            // setFilterCurrentPage(value);
          }}
        />

        <Divider />

        <div className="bizzll-directory">
          {businesses.length > 0 &&
            businesses.map((business) => {
              return (
                <ListingItem
                  key={business.id}
                  business={business}
                  userRoleAccess={userRoleAccess}
                  keyword={keyword}
                />
              );
            })}
        </div>

        <Divider />
        <div className="listing-header">
          <h4 className="results-num">
            Found{" "}
            {businesses.length +
              ((currentPage + 1) * 12 - businesses.length) -
              ((currentPage + 1) * 12 - businesses.length)}{" "}
            of {businessesLength} Results
          </h4>

          <div className="sort-form">
            <form>
              <label>
                <span>Sort By</span>

                <select
                  onChange={(e) => handleSorting(e.target.value)}
                  defaultValue={sorting}
                >
                  <option value="alphabetical">Alphabetical</option>
                  <option value="latest">Latest</option>
                  <option value="highest_rated">Highest Rated</option>
                </select>
              </label>
            </form>

            <Radio.Group onChange={onChange} value={value}>
              <Radio value="asc" className="radioVal">
                ASC
              </Radio>
              <Radio value="desc" className="radioVal">
                DESC
              </Radio>
            </Radio.Group>
          </div>
        </div>

        <Pagination
          align="end"
          current={currentPage}
          total={businessesLength}
          pageSize={12}
          showSizeChanger={false}
          onChange={(value) => {
            setCurrentPage(value);
          }}
        />
      </div>
    );
  }
}
