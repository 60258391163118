import { useState } from "react";
import { FE_URL } from "../common/constants";
import axiosWithAuth from "../utils/axiosWithAuth";
import moment from "moment";

export const useCreateOrder = (
  user,
  cartItems,
  userInfo,
  subTotal,
  amountTotal
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const createOrder = () => {
    setIsSubmitting(true);

    let APIURL = "/api/orders/create";

    let payload = {
      order: {
        user_id: user.id,
        business_id: cartItems[0].businessID,
        status: "Completed",
        subtotal: subTotal,
        total: amountTotal,
      },
      orderItems: cartItems.map((item) => {
        return {
          type: item.itemType,
          item_id: item.itemID,
          quantity: item.itemQuantity,
        };
      }),
      user: {
        fname: user.fname,
        lname: user.lname,
        email: user.email,
      },
    };

    console.log("cartItems", cartItems);

    if (cartItems[0].itemType === "event_ticket") {
      payload = {
        ...payload,
        attendee: {
          user_id: user.id,
          event_id: cartItems[0].sourceID,
          ticket_id: cartItems[0].itemID,
          quantity: 1,
          price: cartItems[0].itemPrice,
        },
      };
    }

    if (cartItems[0].itemType === "service_product") {
      payload = {
        ...payload,
        appointment: {
          buyer_id: user.id,
          product_id: cartItems[0].itemID,
          status: "confirmed",
          start_time: cartItems[0].startTime,
          business_id: cartItems[0].businessID,
        },
      };
    }

    if (cartItems[0].itemType === "restaurant_product") {
      APIURL = "/api/restaurant/order/createOrderRestaurant";

      payload = {
        businesses_id: cartItems[0].businessID,
        date_order: moment(),
        total_price_order: amountTotal,
        delivery_type: userInfo ? "Delivery" : "Pickup",
        delivery_address: userInfo ? userInfo.address : null,
        details: cartItems.map((item) => ({
          dish_id: item.itemID,
          dish_quantity: item.itemQuantity,
          dish_price: item.itemPrice,
          total_price: item.itemTotal,
          extras: item.extras,
        })),
      };
    }

    if (cartItems[0].itemType === "restaurant_product") {
      console.log("payload", payload);
      axiosWithAuth()
        .post(APIURL, payload)
        .then((res) => {
          window.location.replace(
            `${FE_URL}/order-confirmation-restaurant/${res.data.orderID}`
          );
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      axiosWithAuth()
        .post(APIURL, payload)
        .then((res) => {
          window.location.replace(
            `${FE_URL}/order-confirmation/${res.data.orderID}`
          );
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  // Return all necessary functions/states
  return [createOrder, isSubmitting];
};
